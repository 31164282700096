$large-screen: 840px;
$default-font: "Inconsolata", "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
$dark-grey: #2b2b2b;
$light-grey: #f6f4ed;
$pale-grey: #f9f6ef;
$white: #ffffff;
$black: #000000;
$nearly-black: #1e1e1e;
$railscasts-yellow: #d3c9a9;
$railscasts-green: #b4ca73;
$railscasts-blue: #85a0c2;
$railscasts-orange: #9f5c24;
$railscasts-purple: #c3c2ee;
$railscasts-red: #e36049;
$railscasts-grey: #ebe7e3;

// Base
* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: $default-font;
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

p {
  font-family: $default-font;
  font-size: 22px;
}

cite {
  font-size: 26px;
  font-style: normal;

  @media (min-width: $large-screen) {
    font-size: 32px;
  }
}

figure {
  margin-inline-start: 0;
  margin-inline-end: 0;
}

h1, h2 {
  font-family: $default-font;
}

pre {
  white-space: pre-wrap;
  padding: 10px;
}

a {
  background-color: #1f1f1f;
  color: $railscasts-orange;

  &:hover,
  &:focus {
    color: $railscasts-red;
  }
}

hr {
  border: 4px solid $dark-grey;
}

// Nav
.navigation {
  position: fixed;
  width: 100%;
  height: 80px;
  background-color: white;
  z-index: 1;
}

.navigation__logo {
  height: 50px;
  padding: 10px 0 0;

  @media (min-width: $large-screen) {
    height: 70px;
  }
}

.navigation__menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: sticky;
  top: 0;
  font-size: 20px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex !important;
  flex-direction: column;
  background-color: white;

  @media (min-width: $large-screen) {
    flex-direction: row;
    font-size: 28px;
  }
}

.navigation__item {
  padding: 2px 20px 0 10px;
  align-self: left;

  a {
    color: black;
    text-align: center;
    text-decoration: none;
    font-family: $default-font;
    font-weight: 600;
    background-color: $white;
  }

  @media (min-width: $large-screen) {
    align-self: center;
  }
}

.navigation__item--secondary {
  position: fixed;
  top: 0;
  right: 0;

  @media (min-width: $large-screen) {
    position: relative;
    padding-right: 5px;
    margin-left: auto;
  }
}

// Hero
.hero {
  background-color: $dark-grey;
}

.hero__container  {
  padding: 10px;
  max-width: 1200px;
  margin: 0 auto;

  @media (min-width: $large-screen) {
    display: flex;
  }
}

.hero__heading {
  flex: 1;
}

.hero__content {
  flex: 2;
}

.hero__title {
  font-size: 90px;
  font-family: $default-font;
  margin: 10px 0 0 0;
  color: $railscasts-yellow;
  display: table;
  letter-spacing: 8px;

  @media (min-width: $large-screen) {
    font-size: 130px;
    font-weight: 800;
  }
}

.hero__title--word {
  display: table; // https://css-tricks.com/injecting-line-break/
  margin: 0 0 10px;
  background-color: $nearly-black;
  padding: 0 10px;
}

.hero__text {
  margin: 0;
  font-family: $default-font;
  font-size: 30px;
  font-weight: 500;
  color: $railscasts-blue;

  @media (min-width: $large-screen) {
    font-size: 40px;
  }
}

.hero__text--subtitle,
.hero__text--subtitle a {
  color: $railscasts-orange;
  margin-top: 20px;
  font-size: 35px;
}

.hero__text--subtitle a {
  &:hover,
  &:focus {
    color: $railscasts-purple;
  }
}

.hero__syntax {
  font-size: 200%
}

.hero__text--strapline {
  margin: 10px 0;
  color: $white;
  display: flex;
  align-self: end;
}

// Structure

.container {
  flex: 1 0 auto;
  padding: 160px 0;

  @media (min-width: $large-screen) {
    padding: 80px 0;
  }
}

.section {
  flex-direction: column;

  @media (min-width: $large-screen) {
    display: flex;
  }
}

.section__container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;

  @media (min-width: $large-screen) {
    display: flex;
    align-items: baseline;
  }
}

.section__container--secondary {
  background-color: $light-grey;

  @media (min-width: $large-screen) {
    padding: 0 60px;
  }
}

.section__container--inverse {
  background-color: $dark-grey;
  color: $railscasts-orange;
  margin-top: 0;
}

.section__heading {
  flex: 1;
  font-size: 40px;
  font-family: $default-font;
  color: $dark-grey;

  @media (min-width: $large-screen) {
    font-size: 90px;
  }
}

.section__content {
  flex: 2;

  @media (min-width: $large-screen) {
    padding: 30px 0;
  }

  a {
    background-color: $pale-grey;
  }

  h1, aside {
    font-size: 25px;

    @media (min-width: $large-screen) {
      font-size: 35px;
    }
  }

  h2, li {
    font-size: 30px;
    list-style: none;
  }

  ul {
    padding-inline-start: 0;
  }
}

.section__content--logos {
  margin: 0 auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
  list-style-type: none;

  h1 {
    text-align: center;
  }

  @media (min-width: $large-screen) {
    flex-direction: row;
    align-items: baseline;
  }
}

.section__content--logo {
  flex: 1;
  margin: 0 0 60px;

  @media (min-width: $large-screen) {
    margin: 0;
  }
}

.logo {
  width: 100%;
  padding: 0 30px;
  filter: grayscale(100%);

  &:hover {
    filter: grayscale(0%);
  }
}

.section__container--text {
  font-size: 24px;
  font-weight: 600;
  text-align: center;

  a {
    background-color: $pale-grey;
  }
}

.section__container--text-sponsors {
  margin: 40px 0 0 0;
  text-align: center;
}

.section__container--cta {
  text-align: center;
}

.button__cta {
  border: 0;
  border-radius: 4px;
  background-color: $railscasts-red;
  box-shadow: inset 0 -4px 0 0 #bb2d00;
  cursor: pointer;
  outline: 0;
  text-shadow: 1px 1px $nearly-black;
  line-height: 1.3em;
  text-align: center;
  color: $pale-grey;
  font-size: 150%;
  font-weight: 600;
  padding: 30px;
  display: inline-block;

  &:hover,
  &:focus {
    color: $white;
    text-shadow: 1px 1px $dark-grey;
  }

  @media (min-width: $large-screen) {
    padding: 30px 60px;
    text-align: center;
    font-size: 200%;
  }
}

footer {
  position: relative;
  bottom: 0;
  width: 100%;
  flex-shrink: 0;
  background-color: $dark-grey;
  padding: 30px;
  color: $white;

  a {
    color: $white
  }

  a:hover,
  a:focus {
    color: $railscasts-green;
  }
}

input, button, textarea {
  font-family: $default-font;
  font-size: 100%;
  border: 2px solid $dark-grey;
  background-color: $light-grey;
  box-shadow: none;
  padding: 5px;

  &:focus {
    outline: none;
  }
}

input {
  margin: 0 0 10px;
}

textarea {
  min-height: 250px;
  resize: vertical;
}

button {
  cursor: pointer;
  font-weight: 500;
  margin-top: 10px;
  align-self: flex-end;
}

.heading {
  &-card {
    margin: 0 auto;
    border: 1px solid $black;
    max-width: 480px;
    background-color: $white;
    box-shadow: -0.6rem 0.6rem 0 $railscasts-yellow;
  }

  &-card-header {
    text-align: center;
    padding: 8px;
    font-size: 14px;
    font-weight: 700;
    border-bottom: 1px solid $black;
  }
}

.contact-body {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.contact__message {
  margin-top: 10px;
}

.list-item {
  font-size: 22px !important;
  list-style-type: circle !important;
}

.time {
  font-size: 40px;
}

.language-html {
  color: $white;
}

.highlight pre { background-color: #1e1e1e; border-radius: 5px; }
.highlight .s { color: $railscasts-orange } /* Literal.String */
.highlight .na { color: $railscasts-grey } /* Name.Attribute */
.highlight .nt { color: $railscasts-green; font-weight: bold } /* Name.Tag */


.flexbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px;

  .item {
    // padding: 6px;
    margin: 5px;
    border: 5px solid #ebebeb;

    @media (min-width: $large-screen) {
      width: 48%;
    }

    img {
      width: 100%;
      display: block;
    }



  }
}
